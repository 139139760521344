import {takeEvery, all,put} from 'redux-saga/effects'
import  * as constants from './actionTypes'
import axios from 'axios'
import * as actionCreators from './actionCreators'

var urlstr = 'http://139.196.139.138/'

function* saga(){
    
    yield all([
        yield takeEvery(constants.GET_COUNTRY_LIST, fetchCountryList),
    ])
}

function* fetchCountryList(){
  try{
    const res=yield axios.get(urlstr+'api/service-base/country/getCountrys');
    if(res.status===200){
      const data=res.data.data;
      const action = actionCreators.setCountryList(data)
      yield put(action); 
    }
  }catch(e){
        console.log("请求失败"); 
  }
}



export default saga;

//http://139.196.139.138/api/service-user/user/updatePassword
//{"verifyKey":"221125A4F4ZX6Y80","key":"221125A4F4ZX6Y80","password":"Taoke123","resetPassword":"Taoke123"}

//http://139.196.139.138/api/service-user/user/login
//{"userCode":"3035366310","userPassword":"Taoke123","remember":false}