/* eslint-disable no-redeclare */
import React, { useEffect } from "react";
import { Form, message, Row, Col, Button, InputNumber, Divider, Typography } from 'antd';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next'
import { GetDecrease, SaveDecrease } from '../../../../request/api'
var FipSetting = (props) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const [settingForm] = Form.useForm();
  const getDecrease = () => {
    GetDecrease().then(res => {
      settingForm.setFieldValue("Decrease_PV", res.Decrease_PV);
      settingForm.setFieldValue("Decrease_Baterry", res.Decrease_Baterry);
    })
  }

  const onFinish = (values) => {
    SaveDecrease(values).then(res => {
      message.info(t("Message.success"))
    })
  };

  useEffect(() => {
    getDecrease();


  }, [props]); //eslint-disable-line

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Text>FIP参数设置</Text>
      </Divider>
      <Form

        scrollToFirstError={true}
        labelAlign="left"
        form={settingForm}
        onFinish={onFinish}
        name="control-hooks"

      >

        <Row gutter={[16, 8]}>
          <Col xs={24} sm={24} md={10}>
            <Form.Item
              name="Decrease_PV"
              label={t("Fip.SearchForm.rate")}
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputNumber

                className="width100"
                addonAfter="%"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={10}>
            <Form.Item
              name="Decrease_Baterry"
              label={t("Fip.SearchForm.batteryRate")}
              rules={[
                {
                  required: true,
                },
              ]}>

              <InputNumber
                className="width100"
                min={0}
                addonAfter="%"
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={4}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >

            </Form.Item>
          </Col>

        </Row>

      </Form>

    </>
  );
};
const mapStateToProps = (state) => {
  return {

  };
};
const mapDippathToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDippathToProps)(FipSetting);
