import React, { useEffect, useState } from "react";
import { Tabs, Drawer, Row, Col, Button } from 'antd';
import { DownloadOutlined, SettingOutlined,CloseOutlined } from '@ant-design/icons'
import FipSetting from "./components/FipSetting";
import UserSetting from "./components/UserSetting";

import { SettingWapper,SettingDrawer,DrawerHandel } from './style'


const Setting = (props) => {


  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {


  }, []);

  if(false){
    return (
      <SettingWapper>
  
        <DrawerHandel>
           <Button type="primary" style={{display:open?"none":"block"}} onClick={showDrawer} icon={<SettingOutlined />} size="large" />
        </DrawerHandel>
  
  
  
  
  
        <Drawer 
          title=""
          extra={
             
             <Button type="primary"  className="setting-drawer-btn" onClick={onClose} icon={<CloseOutlined />} size="large" />
          
          } 
          closeIcon={null} headerStyle={{position:"absolute",left:"-64px",top:"220px",padding:"0px",border:"0px"}} bodyStyle={{ padding: "0px 0px" }} width={840} placement="right" onClose={onClose} open={open}>
          <Row>
            <Col span={24}>
  
  
              <SettingDrawer>
                <Tabs
                  size="small"
                  tabPosition="left"
                  tabBarStyle={{ width: "160px", height: '100%' }}
                  style={{ minHeight: "100vh",padding:"10px" }}
                  items={[
                    {
                      label: `用户管理`,
                      key: '1',
                      children: <UserSetting/>,
                    },
                    {
                      label: `参数设置`,
                      key: '2',
                      children: <FipSetting />,
                    }
                  ]}
                />
              </SettingDrawer>
            </Col>
  
          </Row>
        </Drawer>
  
      </SettingWapper>)
  }
  
};


export default Setting;
