import { takeEvery, put, all } from 'redux-saga/effects'
import { GET_SELF_CUSTOM_RESULT,GET_SELF_CUSTOM_RESULT2 } from './actionTypes'
import * as actionCreators from './actionCreators'
import { GetSelfCustomResult2,GetSelfCustomResult } from '../../../request/api'
import { message } from 'antd'



function* saga() {

    yield all([
        yield takeEvery(GET_SELF_CUSTOM_RESULT2, fetchSearchList2),
        yield takeEvery(GET_SELF_CUSTOM_RESULT, fetchSearchList),

    ])
}
function* fetchSearchList2(action) {
    try {
        const action2 = actionCreators.SetLoading(true)
        yield put(action2);

         
        //yield put(action);
        //const action3 = actionCreators.SetLoading(false)
         //yield put(action3);

       const res = yield GetSelfCustomResult2(action.data);
        
      
      
        if (res) {
            const data = res;
            const action = actionCreators.SetSelfCustomResult(data)
            yield put(action);
            const action2 = actionCreators.SetLoading(false)
            yield put(action2);

        }
    }
    catch (e) {
        message.error("系统异常,请联系管理人员!");
        const action2 = actionCreators.SetLoading(false)
        yield put(action2);


    }

}

function* fetchSearchList(action) {
    try {
        const action2 = actionCreators.SetLoading(true)
        yield put(action2);

         
        //yield put(action);
        //const action3 = actionCreators.SetLoading(false)
         //yield put(action3);

       const res = yield GetSelfCustomResult(action.data);
        
      
      
        if (res) {
            const data = res;
            const action = actionCreators.SetSelfCustomResult(data)
            yield put(action);
            const action2 = actionCreators.SetLoading(false)
            yield put(action2);

        }
    }
    catch (e) {
        message.error("系统异常,请联系管理人员!");
        const action2 = actionCreators.SetLoading(false)
        yield put(action2);


    }

}


export default saga;



