import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import cookie from 'react-cookies'
import { useTranslation } from 'react-i18next'
import { useState } from 'react';

import IconSvg from '../IconSvg';

import { LanguageWrapper } from './style'

//根据语言id获取语言名称
const getLanguageName = (language) => {
  let languageName;
  // eslint-disable-next-line default-case
  switch (language) {
    case 'zhCN': languageName = '中国'; break;
    case 'jaJP': languageName = '日本'; break;
    // case 'enUS': languageName = 'English'; break;
  }
  return languageName;
}

//传入不同的svg文件名称生成不同国家语言icon图标
const LanguageIcon = ({ svgName, className }) => {

  return (
    <>
      <img alt="chinese" src={require("../../statics/flags/1x2/" + svgName + ".svg")} height={10} className='m-r-5' />
    </>
  )
}
//语言类型集合
const items = [
  {
    label: getLanguageName('zhCN'),
    key: 'zhCN',
    icon: <LanguageIcon svgName='cn' />,
  },
  {
    label: getLanguageName('jaJP'),
    key: 'jaJP',
    icon: <LanguageIcon svgName='jp' />,
  },
  // {
  //   label: getLanguageName('enUS'),
  //   key: 'enUS',
  //   icon: <LanguageIcon svgName='un' />,
  // },
];


//语言选择组件
const ChooseLanguage = (props) => {
  const { i18n } = useTranslation();

  const [languageName, setLanguageName] = useState(getLanguageName(i18n.language))


  //点击切换语言事件
  const handleLanguageMenuClick = (e) => {
    // i18n.changeLanguage(e.key);
    // cookie.save('language', e.key, { path: '/' });
    // setLanguageName(getLanguageName(e.key))
    //props.getLanguage(e.key);
    debugger
   
    if(e.key==="zhCN"){
      window.location.href = "https://www.pvbao.cn"
    }else{
      window.location.href = "https://www.pvbao.net"
    }
  };

  const menuProps = {
    items,
    onClick: handleLanguageMenuClick,
  };


  return (
    <>
      <LanguageWrapper>
        <Space wrap>
          <Dropdown menu={menuProps} placement="bottom">
            <Button shape={props.shape} size="small" block>
              <Space>
                {languageName}
                <IconSvg icon="fal fa-angle-down" size="small" />
              </Space>
            </Button>
          </Dropdown>
        </Space >
      </LanguageWrapper>
    </>
  )
}

export { ChooseLanguage }