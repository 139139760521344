import styled from 'styled-components'
export const HeaderWrapper = styled.div`
  width: 100%;
  flex-flow: nowrap;
  height: 64px;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
  .version{
    float: left;
  }
  .version span {
    font-size: 9px;
    color: rgba(255,255,255,1);
    padding: 5px 10px;
    background: #c0cfce;
    border-radius: 50px;

  }
 
`


export const Header = styled.div`
  /* min-width: 600px; */
  width:50%;
  min-width:840px;

`




export const HeaderTitle = styled.div`

`