import React, { useEffect, useState } from "react";
import { HeaderWrapper, HeaderTitle, Header } from "./style";
import { UserOutlined, LoginOutlined,SettingOutlined } from '@ant-design/icons';
import { Row, Col, Space, Typography, Card, Dropdown, Avatar, Menu } from 'antd'
import { ChooseLanguage } from '../chooselanguage'
import { useNavigate } from 'react-router-dom'
import { Logo } from "../logo/index"
import cookie from 'react-cookies'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography;
const { Meta } = Card;



const Home = (props) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState({})
  const [open, setOpen] = useState(false);
  const history = useNavigate()
  const menu = (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {

        if (key === '2') {
          cookie.save('user', '');
          history({ pathname: '/login' })
        }

      }}
      items={[
        // {
        //   key: 1,
        //   label: '设置管理',
        //   icon: <SettingOutlined />
        // },
        {
          key: 2,
          label: t("Login.loginOut"),
          icon: <LoginOutlined />
        },

      ]}
    />
  );


  useEffect(() => {
    var userId = cookie.load("user");
    if (userId !== undefined && userId !== '') {
      setUserId(userId)
    } else {
      history({ pathname: '/login' })
    }

  }, []);








  return (
    <>
      <HeaderWrapper>
        <Header>
          <Row align="middle" justify="space-between" >
            <Col span={4}> <Logo /></Col>
            <Col span={1}>  <div className="version"><span className="color-4">{t('Global.version')}</span></div></Col>
            <Col span={2} align="right"><Text>{t("Login.welcome")}</Text> </Col>

            <Col span={9}><HeaderTitle /></Col>
            <Col span={3}><ChooseLanguage /></Col>
            
            <Col span={5} className="login" style={{ textAlign: "right" }}>
             
              <div style={{ display: userId === '' ? 'none' : 'block' }} className="user-center" >

                <Dropdown overlay={menu}>
                  <Space>
                    <Card bordered={false}>
                      <Meta
                        avatar={<Avatar size="small" style={{ backgroundColor: '#16a085' }} icon={<UserOutlined />} />}
                        title={userId.UserName}
                      />

                    </Card>
                  </Space>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
      </HeaderWrapper>

    </>)
};


export default Home;
