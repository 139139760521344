import { legacy_createStore as createStore, compose ,applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducer';


import { sagas as loginSagas } from '../pages/Login/store'
import { sagas as printPageSagas } from '../pages/PrintPage/store'
import { sagas as printPageFipSagas } from '../pages/PrintPageFip/store'
import { sagas as printPageIncomeSagas } from '../pages/PrintPageIncome/store'
import { sagas as homeSagas } from '../pages/Home/store'
const sagaMiddleware=createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store=createStore(reducer,composeEnhancers(
    applyMiddleware(sagaMiddleware)
));


let sagas=[...loginSagas,...printPageSagas,...homeSagas,...printPageFipSagas,...printPageIncomeSagas];
for(let i=0;i<sagas.length;i++){
    sagaMiddleware.run(sagas[i]);
}




// const sagasLogin=[...loginSagas];
// for(let i=0;i<sagasLogin.length;i++){
//     sagaMiddleware.run(sagasLogin[i]);
// }
//sagaMiddleware.run(loginSagas);
// sagaMiddleware.run(menuSagas);


export default store