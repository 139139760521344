// import React from "react";
// import { ReactSVG } from 'react-svg';
// import './style.css'


// const IconSvg = (props) => {

//   const ctx = require.context('../../statics/svg/duotone/', true, /\.svg$/)
//   const map = {}
//   for (const key of ctx.keys()) {
//     map[key] = ctx(key)
//   }
//   console.log(map['./' + props.icon + '.svg'])
//   console.log(88888)
//   console.log(props.icon)
//   return (
//     <>
//       <ReactSVG className={`${props.className} ${typeof (props.size) != 'undefined' ? props.size : ''} icon-svg `} src={map['./' + props.icon.replace('fa-', '') + '.svg']} />
//       {/* style={{fill:props.color,fontSize:props.fontSize+'px'}} */}
//     </>
//   )
// }

// export default IconSvg


import React from "react";
import { ReactSVG } from 'react-svg';
import './style.css'



const svgType = (type) => {
  let ctx;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'fal':
      ctx = require.context('../../statics/svg/light/', true, /\.svg$/)
      break;

  }
  return ctx;

}
const IconSvg = (props) => {
  const map = {}
  const icons = props.icon.split(' ');
  let icon = '';

  if (icons.length > 1) {
    icon = icons[1]
    const ctx = svgType(icons[0])
    //const ctx = require.context('../../statics/svg/solid/', true, /\.svg$/)

    for (const key of ctx.keys()) {
      map[key] = ctx(key)


    }
    //console.log('icon',icon,'url',map['./' + icon.replace('fa-', '') + '.svg'])
  }
  else {

    icon = icons[0]

    const ctx = require.context('../../statics/svg/light/', true, /\.svg$/)
    for (const key of ctx.keys()) {
      map[key] = ctx(key)
    }
  }

  return (
    <>
      <ReactSVG className={`${props.className} ${typeof (props.size) != 'undefined' ? props.size : ''} icon-svg `} src={map['./' + icon.replace('fa-', '') + '.svg']} />
      {/* style={{fill:props.color,fontSize:props.fontSize+'px'}} */}
    </>
  )
}

export default IconSvg