import styled from "styled-components";
import img1 from '../../statics/images/zjxf.jpg';
import img2 from '../../statics/images/fip.jpg';
import img3 from '../../statics/images/3.jpg';
import img4 from '../../statics/images/fzgj.jpg';
import img5 from '../../statics/images/rgfa.jpg';
import imgBg from '../../statics/images/background.png';
export const LoginBox = styled.div`
  //background: red;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  //background-image: url('${(props) => props.bg}');
  backdrop-filter: blur(4px);
  background-size: 100% 100% !important;
  /* background: url(${imgBg}) 100% 100% no-repeat; */

  background-image: url(${imgBg});
background-repeat: no-repeat, repeat;
background-attachment: local, fixed;
background-size: auto, cover;
background-position: center;

  url(big-pic-min.png), url(big-pic.png)
              .ant-card{
              background: initial !important;
            }

            .ant-card-meta-title{
             color:#fff
            }
            .ant-card-body{
              padding:0px !important;
            }
            .user-center{
              vertical-align: middle !important;
            }

            .lazy-background {
              background-image: url("hero-placeholder.jpg"); /* 占位图片 */
            }

            .lazy-background.visible {
              background-image: url("hero.jpg"); /* 真正的图片 */
            }

  
`;

export const LoginCardWrapper = styled.div`

      min-width: 600px;
      width:840px;
      .login_header{
            width: 100%;
            padding-top:10px;
            .ant-col{
              line-height: 55px !important;
            }
            .log-in{
              color: #969696 !important;
              font-size:15px !important;
            }
            .header_title{
              font-weight: 100;
              text-align: center;
              line-height: inherit;
            }
      }


      .login_body{
            margin-top: 110px;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction:column;
          

                .img1{
                  width: 100%;
                  height:100%;
                  background: url(${img1}) 100% 100%;
                   background-size: cover; 
                  
                }
                .img2{
                  width: 100%;
                    height:100%;
                  background: url(${img2}) 100% 100%;
                   background-size: cover; 
                }
                .img3{
                  width: 100%;
                    height:100%;
                  background: url(${img3}) 100% 100%;
                   background-size: cover; 
                }
                .img4{
                  width: 100%;
                  height:100%;
                  background: url(${img4});
                   background-size: cover; 
                }
                .img5{
                  width: 100%;
                  height:100%;
                  background: url(${img5}) 100% 100%;
                   background-size: cover; 
                }

                .captions{
                    width: calc(100% - 16px) ;
                    z-index: 9999;
                    text-align: center;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3.0rem;
                    background: rgba(0,0,0,0.6);
                    color: #fff ;
                    font-size: 16px;
                    bottom:16px !important;
                    h5{
                      font-weight: 100 !important;
                    }
                }


                
                .card{
                  overflow: hidden;
                  margin-bottom: 16px;
                  align-items: flex-end;
                  height:235px;
                  cursor: pointer;
    
                }
                .card:hover{
                 
                  .imgBox{
                    transform: scale(1.5);
                    transition: all 0.8s;
                  }
                }
            



            .ant-card-body{
              display: none !important;
            }

            .ant-card-bordered{
              border:0px;
            }

           


    
`;
