import { combineReducers } from 'redux-immutable';

import {reducre as loginReducer} from '../pages/Login/store';
import {reducre as homePageReducer} from '../pages/Home/store';
import {reducre as printPageReducer} from '../pages/PrintPage/store';
import {reducre as printPageReducerFip} from '../pages/PrintPageFip/store';
import {reducre as printPageReducerIncome} from '../pages/PrintPageIncome/store';


const appReducer = combineReducers({
    /* your app’s top-level reducers */
    // psinfoList:psinfoListReducer,

    login:loginReducer,
    printPage:printPageReducer,
    printPageFip:printPageReducerFip,
    home:homePageReducer,
    printPageIncome:printPageReducerIncome

  })
  
  const rootReducer = (state, action) => {
    if (action.type === 'RESET_DATA') {
      //退出登录清空store中数据
      
      state = undefined
    }
  
    return appReducer(state, action)
  }


  export default rootReducer