//导入我们封装好的axios 
import service from './index'

export const basicIp="http://47.91.16.83:885";

//export const basicIp="https://www.smartom.com";

//用户登录接
export const UserLogin = userInfo => service.post('User/Login', userInfo);



//根据用户ID获取用户信息
export const GetCities = () =>service.post('Data/GetCities')




//获取担当
export const GetSales = () =>service.post('Data/GetSales')


//查询同名的Demand文件Upload/GetSchema
export const GetSchema = (fileName) =>service.get('Upload/GetSchema?fileName='+encodeURI(fileName))

//得到demand数据
export const GetDemandData = (projectId) =>service.get('Data/GetDemandData?projectId='+encodeURI(projectId))


//上传Demand模板
export const Demand = (formDate,config) =>service.post('Upload/Demand',formDate,config)

//根据Demand模板
export const GetProjects = () =>service.post('Data/GetProjects')



//新增方案
export const SaveQueryAs = psConfig =>service.post('Data/SaveQueryAs',psConfig)

//修改方案
export const SaveQuery = psConfig =>service.post('Data/SaveQuery',psConfig)


//保存fip 计算方式
export const SaveFipMethod = data =>service.post('Data/SaveFipMethod',data)


//获取fip计算方式
export const GetFipMethods = () =>service.post('Data/GetFipMethods')


export const GetQueryList = (psmode) =>service.get('Data/GetQueryList?psmode='+psmode)


//自家消费生成excel
export const SelfCustomExcel = psConfig =>service.post('Data/SelfCustomExcel',psConfig)

//自家消费 在线报告生成
export const GetSelfCustomResult = psConfig =>service.post('Data/GetSelfCustomResult',psConfig)

//自家消费收益计算 在线报告生成
export const GetSelfCustomResult2 = psConfig =>service.post('Data/GetSelfCustomResult2',psConfig)



//FIP 获取校对的excel、
export const GetFipCheckExcel = psConfig =>service.post('Data/GetFipCheckExcel',psConfig)


//返回的是一个对象，里面有2个字段：Decrease_PV 光伏衰减、Decrease_Baterry 蓄电池衰减
export const GetDecrease = () =>service.post('Data/GetDecrease')


//保存 Decrease_PV 光伏衰减、Decrease_Baterry 蓄电池衰减
export const SaveDecrease = psConfig =>service.post('Data/SaveDecrease',psConfig)


//得到套餐公司
export const GetFeeComboCompany = () =>service.get('Data/GetFeeComboCompany')

//得到套餐类型
export const GetFeeComboTypes = (companyId) =>service.get('Data/GetFeeComboTypes?companyId='+companyId)

//得到套餐
export const GetFeeCombo = (typeId) =>service.get('Data/GetFeeCombo?typeId='+typeId)










