import React,{useEffect,useState,Suspense,lazy} from "react";
import { connect } from "react-redux";
import {Routes,Route,Navigate,useLocation } from 'react-router-dom'
import { Wrapper,PageContent, PageWrapper } from "./style";
import Header from "../../common/header";
import Setting from "../../common/setting";
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import jaJP from 'antd/es/locale/ja_JP';
import { ConfigProvider } from 'antd';


const Home = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/Home'))

const Fip = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/Fip'))

const PrintPage = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/PrintPage'))


const PrintPageFip = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/PrintPageFip'))

const PrintPageIncome = lazy(() => import(/* webpackChunkName: 'home' */'../../pages/PrintPageIncome'))

const Content=(props)=>{

    const [locale, setLocal] = useState(null);
    const location = useLocation();


    const isBackground = () =>{
        if(location.pathname==="/printPage" || location.pathname==="/printPageFip"  || location.pathname==="/printPageIncome"){
            return true;
        }else{
            return false;
        }
    }


    //切换语言
    useEffect(()=>{
      
      switch("zh-cn"){
      case "zh-cn":
          setLocal(zhCN)
      break;
      case "ja-jp":
          setLocal(jaJP)
      break;
      case "en-us":
          setLocal(enUS)
      break;
      default:setLocal(zhCN);break;
    }
    
  },[])
    return(
        <>
      

            <Header />
            <ConfigProvider locale={locale}>
                <Wrapper className= {`${ isBackground()?'isBackground':'' }`}>
                <Setting/>
                <PageWrapper  className= {`page-wrapper ${ isBackground()?'isBackground':'' }`}  >
                    <PageContent>
                    <Suspense > 

                     {/*<Routes>
                        <Route path='/' element={<Home/>} />
                        <Route path="/page/home" element={<Home />}></Route>
                        <Route path="about" element={<About />}></Route>
                        <Route path="/deviceReport" element={<deviceReport />}></Route>
                    </Routes>*/}
                    {/* 注册路由 */}
                    <Routes>
                        <Route path="/home" element={<Home/>}  />
                        <Route path="/fip" element={<Fip/>}  />
                        <Route path="/printPage" element={<PrintPage/>}  />
                        <Route path="/printPageFip" element={<PrintPageFip/>}  />
                        <Route path="/PrintPageIncome" element={<PrintPageIncome/>}  />
                        <Route path="*" element={<Navigate to="/home"/>}/>

                    </Routes>
                    </Suspense>
                    
                    </PageContent>
                </PageWrapper>
                </Wrapper>
        
            </ConfigProvider>
        </>
    )
}
const mapState=(state)=>{
    return{

    }
}

export default connect(mapState,null)(Content) 