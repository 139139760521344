import * as actions from './actionTypes'

export const GetSelfCustomResult2=(data)=>({
  type:actions.GET_SELF_CUSTOM_RESULT2,
  data,
})

export const GetSelfCustomResult=(data)=>({
  type:actions.GET_SELF_CUSTOM_RESULT,
  data,
})

export const SetSelfCustomResult=(data)=>({
  type:actions.SET_SELF_CUSTOM_RESULT,
  data,
})

export const SetLoading=(data)=>({
  type:actions.SET_LOADING,
  data,
})


