import styled from 'styled-components'


export const Wrapper=styled.div`
    width: 100%;
  flex-flow: nowrap;
  height: 100%;
  display: flex;
  justify-content: center;
    position: relative;

    
  background: rgba(95,95,95,.02);
    
`

export const PageWrapper=styled.div`
   /* min-width: 600px; */
  /* width:50%; */
  min-width:840px;
  padding-top:20px;
  .ant-form{
    max-width: 100% !important;
  }
    
`

export const PageContent=styled.div`
 
  `
