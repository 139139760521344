import React from "react";
import { Routes, Route } from 'react-router-dom'

import { GlobalStyle } from './style';

import './statics/css/app.css'
import { Provider } from 'react-redux'
import Content from "./common/content";
import store from './store';
import Login from "./pages/Login";
// import PrintPage from "./pages/PrintPage"
import './i18n/config'

function App() {

  return (
    <>
      <Provider store={store}>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          {/* <Route path='/printPage' element={<PrintPage />} /> */}
          <Route path="/*" element={<Content />} /> 
        </Routes>
      </Provider>
      <GlobalStyle />
    </>
  )
}

export default App;