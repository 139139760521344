import styled from 'styled-components'

export const LanguageWrapper=styled.div`
    .ant-btn {
        min-width: 80px !important;
    }

    .ant-btn .icon-svg {
        transition: all .3s;
    }

    .ant-btn:hover .icon-svg {
        color: var(--primary-color) !important;
        fill: var(--primary-color) !important;
    }
`
