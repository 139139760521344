import styled from 'styled-components'

export const LogoImgWrapper=styled.div`
    /* position:absolute;
    top:0px;
    left:0px;
    display: block; */
    max-width:135px;
    /* height:56px; */


`


