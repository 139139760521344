import styled from 'styled-components'


export const SettingWapper = styled.div`
 

  
 
`
export const DrawerHandel = styled.div`
   
    position: fixed;
        right: 0px;
        top: 220px;
        z-index: 2147483640;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .ant-btn{
          border-radius: 6px 0px 0px 6px !important;
        }
      
  
 
`

export const SettingDrawer = styled.div`
  .ant-tabs-tab-active {
      background-color: #e6f4ff !important;
  }

  .ant-pro-setting-drawer-handle {
        position: fixed;
        right: 0px;
        top: 220px;
        z-index: 2147483640;
        display: flex;
        flex-direction: column;
        cursor: pointer;
  }

  .ant-pro-setting-drawer-handle > .ant-btn{

    border-radius: 6px 0px 0px 6px !important;
  }
  

 
`
