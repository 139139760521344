import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';



const defaultState=fromJS({
    list:false
});

// eslint-disable-next-line import/no-anonymous-default-export
const homeGlobalReducer = (state = defaultState,action) =>{

  switch(action.type){
    default:
      return state;
  }
}




const defaultCombine = combineReducers({
  global:homeGlobalReducer,
})

export default defaultCombine