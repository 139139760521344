import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input,Divider,Typography } from 'antd';
import { useTranslation } from 'react-i18next'
import {Login} from './style'

const LoginForm = (props) => {
  const { t } = useTranslation();
  const [loginForm] = Form.useForm();


  const { Text } = Typography;

  const onFinish = (values) => {
    loginForm.validateFields().then(values => {

      props.loginHandel(values);
    })
  };
  return (
    <Login  >
      <Divider>
        <Text strong >{t("Login.Login")}</Text>
      </Divider>
      <Form
        name="normal_login"
        form={loginForm}
        className="login-form"
    
        onFinish={onFinish}
      >
        <Form.Item
          name="UserCode"
          rules={[
            {
              required: true,
              message: t("Login.inputUser"),
            },
          ]}
        >
          <Input size="large"   value="admin" prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Login.userName")} />
        </Form.Item>
        <Form.Item
          name="Password"
          rules={[
            {
              required: true,
              message: t("Login.inputPassword"),
            },
          ]}
        >
          <Input
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("Login.password")}
          />
        </Form.Item>
        {/* <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>记住密码</Checkbox>
          </Form.Item>
        </Form.Item> */}

        <Form.Item>
          <Button size="large" type="primary"  htmlType="submit" style={{width:'100%'}} className="login-form-button">
            {t("Login.Login")}
          </Button>
        </Form.Item>
      </Form>
    </Login>
  );
};
export default LoginForm;