/* eslint-disable no-redeclare */
import React, { useEffect, useState } from "react";
import { Space, Table, Select, Input, Button, Divider, Typography, Tag, Modal, Form, Popconfirm } from 'antd';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next'
import { } from '../../../../request/api'
var UserSetting = (props) => {
  const { Text } = Typography;
  const { Search } = Input;
  const { Option } = Select;



  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (key) => {
    console.log("key1111111", key);
  };

  const columns = [
    {
      title: '账号',
      align: "center",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '用户名称',
      align: "center",
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: '角色',
      align: "center",
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <>

          <Tag color="orange" key={address}>
            {address}
          </Tag>

        </>
      )
    },
    {
      title: '操作',
      key: 'address',
      align: "center",
      width: "120px",
      render: (_, record) => (
        <Space size="middle">
          <Button  type="link" size="small">
              修改
          </Button>

          <Popconfirm placement="leftTop" title="是否删除该账号?" onConfirm={() => handleDelete(record.key)}>
            <Button  type="link" size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: '管理员',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: '普通用户',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: '普通用户',
      tags: ['cool', 'teacher'],

    },
  ];

  const selectBefore = (
    <Select defaultValue="1" style={{
      width: 120,
    }} className="select-before">
      <Option value="1">账号</Option>
      <Option value="2">用户名称</Option>
    </Select>
  );


  const [form] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case 'male':
        form.setFieldsValue({
          note: 'Hi, man!',
        });
        return;
      case 'female':
        form.setFieldsValue({
          note: 'Hi, lady!',
        });
        return;
      case 'other':
        form.setFieldsValue({
          note: 'Hi there!',
        });
    }
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: 'Hello world!',
      gender: 'male',
    });
  };

  const SearchForm = () => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          <Text>用户管理</Text>
        </Divider>
        <Input.Search addonBefore={selectBefore} className="ant-input-no" defaultValue="" style={{ width: "60%" }} />
        <Button type="primary" style={{ float: "right" }} onClick={showModal}>新增</Button>


        <Modal title="保存用户" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item
              name="账号"
              label="账号"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="用户名称"
              label="用户名称"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="角色"
              label="角色"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="请选择用户角色"
                onChange={onGenderChange}
                allowClear
              >
                <Option value="1">管理员</Option>
                <Option value="2">普通用户</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="密码"
              label="密码"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

          </Form>
        </Modal>

      </>

    )
  }


  useEffect(() => {



  }, [props]); //eslint-disable-line

  return (
    <>
      <SearchForm />
      <Table style={{ marginTop: "10px" }} columns={columns} bordered={true} dataSource={data} size="small" />
    </>
  );
};
const mapStateToProps = (state) => {
  return {

  };
};
const mapDippathToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDippathToProps)(UserSetting);
