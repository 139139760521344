//在index.js中引入axios
import axios from 'axios';
import cookie from 'react-cookies'
// import { useNavigate } from 'react-router-dom';
//引入qs模块，用来序列化post类型的数据
// import QS from 'qs';
//antd的message提示组件，大家可根据自己的ui组件更改。
 import { message } from 'antd'

//保存环境变量
const isPrd = process.env.NODE_ENV === 'production';


//区分开发环境还是生产环境基础URL
//export const basicUrl = isPrd ? 'http://139.196.139.138/api/' : 'http://139.196.139.138/api/'
//export const basicUrl = isPrd ? 'http://47.91.16.83:885' : 'http://47.91.16.83:885'

export const basicUrl = isPrd ? '/api2' : '/api2'
//export const basicUrl = isPrd ? 'https://www.production.com' : 'http://localhost:7070/api/'

//设置axios基础路径
const service = axios.create({
  baseURL: basicUrl,
  async:true,
  crossDomain:true,
})



// 请求拦截器
service.interceptors.request.use(config => { 
  // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
    //设置请求头
  //const token = cookie.load('userToken') || cookie.load('userToken');
  //config.headers.AuthorizationToken=token;
  //config.headers.AuthorizationUserId=cookie.load('userId');// window.sessionStorage.getItem("userId");


  config.headers.Accept="*";

  

  return config
}, error => { 
    return error;
})

// 响应拦截器
service.interceptors.response.use(response => {
 // const urlRoute = useNavigate();
  //根据返回不同的状态码做不同的事情
  // 这里一定要和后台开发人员协商好统一的错误状态码
  if (response.data) {
    switch (response.data.status) {
      case 200:
        return response.data;
      case 401:
        //未登录处理方法
        break;
      case 403:
        //token过期处理方法
        break;
      case 505:
        window.sessionStorage.removeItem("userToken");
        window.sessionStorage.removeItem("userId");
        window.location.href='/login';
        return null;
      case 500:
        console.log("接口报错")
        message.error("系统异常,请联系管理人员!");
        return response.data;
          //token过期处理方法
        case 503:
          cookie.remove('userToken')
          cookie.remove('userId')
          window.location.href='/login';
            //token过期处理方法
          break;
      default:
        return response.data;
        //message.error(response.data.msg)
    }
  } else { 
    return response;
  }
// }, error => {

//   switch (error.response.status) {
//     case 500:

//       message.error("系统异常,请联系管理人员!");
//       return error.response.data+"222";
//     default:
//       break;
//   }

})
//最后把封装好的axios导出
export default service