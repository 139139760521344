
import styled from "styled-components";

export const Login = styled.div`
  .ant-form-item {
    margin-bottom: 12px !important;
    input{
      width:220px ;
    }
  }
  

  /* background: #fff;
  padding:15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%); */

`
