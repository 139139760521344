import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Avatar, Popover, message, Dropdown, Menu, Space, Card, Modal } from 'antd';
import { useTranslation } from 'react-i18next'
import { UserOutlined, LoginOutlined } from '@ant-design/icons';
import { LoginCardWrapper, LoginBox } from './style'
import { useNavigate } from 'react-router-dom'
import { UserLogin } from '../../request/api'
import cookie from 'react-cookies'
import imgBg from '../../statics/images/background.png';
import imgTitleCn from '../../statics/images/title-cn.png';
import imgTitleJp from '../../statics/images/title-jp.png';
import LoginForm from '../../common/login'
import md5 from 'js-md5';
import { Logo } from "../../common/logo"
import { ChooseLanguage } from '../../common/chooselanguage'
const { Title, Text } = Typography;
const { Meta } = Card;
const Login = (props) => {
    const { t } = useTranslation();
    const [userId, setUserId] = useState('')
    const [open, setOpen] = useState(false);
    const [tempUrl, setTempUrl] = useState('/home');

    const [messageApi, contextHolder] = message.useMessage();
    const history = useNavigate()



    const menu = (
        <Menu
            onClick={({ item, key, keyPath, domEvent }) => {

                if (key === '1') {
                    setOpen(true);
                } else if (key === '2') {
                    cookie.save('user', '');
                    setUserId("");
                }

            }}
            items={[
                // {
                //   key: 1,
                //   label: '切换账号',
                //   icon: <UserSwitchOutlined/>
                // },
                {
                    key: 2,
                    label: t('Login.loginOut'),
                    icon: <LoginOutlined />
                },

            ]}
        />
    );

    useEffect(() => {
        var userId = cookie.load("user");
        if (userId !== undefined) {
            setUserId(userId)
        }


    }, []);

    //打开登录框
    const openLogin = (newOpen) => {
        setOpen(newOpen)
    }

    //登录
    const login = (values) => {

        console.log("md5444444444444", md5("iikeshiki2022"))
        //var params={"UserCode":"admin1","Password":"9101b0e5026420e5cab4bbc3b13391c4"}
        var params = { ...values }
        params["Password"] = md5(params["Password"])

        UserLogin(params).then(res => {

            cookie.save('user', JSON.stringify(res));
            setUserId(res)
            setOpen(false)
            tranUrl(tempUrl);
        }).catch(err => {
            message.error(err.response.data.Message)
        })


    }



    const info = (message) => {
        messageApi.open({
            type: 'info',
            content: message,
            duration: 3,
        });
    };



    const openNotification = () => {
        showModal();
    };

    //跳转链接
    const tranUrl = (url) => {
        setTempUrl(url);
        if (url !== "") {
            if (url === "-1") {
                openNotification();
                return;
            }
            if (cookie.load('user') === "" || cookie.load('user') === undefined) {
                //未登录打开登录窗口
                // info('请先登录！');

                setOpen(true);
            } else {
                history({ pathname: url })
            }

        } else {

            info(t('Login.tips'));
        }



    }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };




    return (
        <>
            {contextHolder}
            <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
                <div>
                    <h5>{t('Login.contactUs')}</h5>
                    <hr />
                    <p className="mb-2">
                        <strong>
                        {t('Login.email')}
                           
                        </strong>
                    </p>
                    <p className="mb-2">
                        <span className="el-tag el-tag--success el-tag--light">
                            <span> {t('Login.contactInformation')}</span>
                        </span>
                    </p>

                    <hr />
                    <p className="mb-2"><strong>{t('Login.telLabel')}</strong></p>
                    <p className="mb-1"><span className="el-tag el-tag--success el-tag--light">
                        <span className="x-large">03-5439-6575</span>
                    </span>
                    </p>
                    <p className="mb-0"><small>{t('Login.time')}</small></p>
                </div>
            </Modal>
            <LoginBox bg={imgBg}>
                <LoginCardWrapper >

                    <div className="login_header">
                        <Row align="middle" justify="space-between" >
                            <Col span={4}> <Logo /></Col>
                            <Col span={14} align="">
                            {t("Login.isTitle")} 
                                {/* <Title className="header_title" level={3} style={{ color: "#fff" }}>{t('Login.title')}</Title> */}
                                <img style={{width:250,display:t("Login.isTitle")?"none":"block",margin:"0 auto"}} alt="" src={imgTitleCn}/>
                                <img style={{width:420,display:t("Login.isTitle")?"block":"none",margin:"0 auto"}} alt="" src={imgTitleJp}/>
                            </Col>

                            <Col span={2}><ChooseLanguage/></Col>
                            <Col span={4} style={{ textAlign: "right" }} >
                                {/* <a className="log-in" href="javascript:void(0)">登录</a> */}

                                <Popover content={<LoginForm loginHandel={login} />} onOpenChange={openLogin} trigger="click" open={open} >

                                    <Avatar size="small" style={{ display: userId === '' ? 'inline-block' : 'none' }} icon={<UserOutlined />} />

                                </Popover>

                                <div style={{ display: userId === '' ? 'none' : 'block' }} className="user-center" >

                                    <Dropdown overlay={menu}>
                                        <Space>
                                            <Card bordered={false}>

                                                <Meta
                                                    avatar={<Avatar size="small" style={{ backgroundColor: '#16a085' }} icon={<UserOutlined />} />}
                                                    title={userId.UserName}
                                                />

                                            </Card>
                                        </Space>
                                    </Dropdown>



                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="login_body">
                        <Row gutter={16}>
                            <Col xs={12} sm={12} md={8}  >

                                <div className="card" onClick={() => tranUrl("/home")}>
                                    <div className="captions"   >
                                        <Title className="header_title" level={5} style={{ color: "#fff" }}>{t('Login.consume')}</Title>

                                    </div>
                                    <div className="imgBox img1"></div>

                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8}  >
                                <div className="card " onClick={() => tranUrl("/fip")} >
                                    <div className="captions">
                                        <Title className="header_title" level={5} style={{ color: "#fff" }}>FIP</Title>

                                    </div>
                                    <div className="imgBox img2">

                                    </div>

                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} >
                                <div className="card " onClick={() => tranUrl("")} >
                                    <div className="captions">
                                        <Title className="header_title" level={5} style={{ color: "#fff" }}>{t('Login.battery')}</Title>

                                    </div>
                                    <div className="imgBox img3">

                                    </div>

                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} onClick={() => tranUrl("")} >
                                <div className="card "  >
                                    <div className="captions">
                                        <Title className="header_title" level={5} style={{ color: "#fff" }}>{t('Login.assist')}</Title>

                                    </div>
                                    <div className="imgBox img4">

                                    </div>

                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} onClick={() => tranUrl("-1")}>
                                <div className="card "  >
                                    <div className="captions">
                                        <Title className="header_title" level={5} style={{ color: "#fff" }}>{t('Login.man')}</Title>

                                    </div>
                                    <div className="imgBox img5">

                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: "40px" }}>
                            <Col offset={16}   >

                                <Text style={{ color: '#bfbfbf',display:"block",textAlign:"left",lineHeight: "normal" }}>{t('Login.info')}</Text>

                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginTop: "80px" }}>
                            <Col offset={1}   >

                                <Text style={{ color: '#bfbfbf',display:"block",textAlign:"left",lineHeight: "normal" }}>{t('Global.version')}</Text>

                            </Col>
                        </Row>
                    </div>
                </LoginCardWrapper>
            </LoginBox>

        </>)
};


export default Login;
