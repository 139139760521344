import React from 'react';
import { LogoImgWrapper } from './style';
import { Link } from "react-router-dom"
import logo from '../../statics/images/SmartOM.svg';

const Logo = () => {
  return (
    <>
      <LogoImgWrapper>
      <Link
                        to={{
                          pathname:"/"
                        }}
                        state={{ }}
                      >
                        <img alt="logo" style={{ height: "48px",marginTop: "-20px"}} src={logo}  />
                      </Link>
       
      </LogoImgWrapper>
    </>
  )
}

export { Logo }