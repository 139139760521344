import * as actions from './actionTypes'
import { fromJS } from 'immutable';

const defaultState = fromJS({
  selfCustomResult: {},
  loading: false,
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_SELF_CUSTOM_RESULT:
      return state.set('selfCustomResult', action.data);
    case actions.SET_LOADING:
      return state.set('loading', action.data);
    default:
      return state;
  }
}