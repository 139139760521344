import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    /* table, caption, tbody, tfoot, thead, tr, th, td, */
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font-family: 小塚明朝 pro,小塚明朝 pr6n,Yu Gothic UI,ＭＳ Ｐゴシック,MS PGothic,sans-serif;
        vertical-align: middle;
      
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1 !important;
        font-size: 14px !important;
        color: #4c5258 !important;
        letter-spacing: .5px !important;
        background: #fff !important;
        overflow-x: hidden !important;
        font-family: Roboto, sans-serif !important;
        /* font-size: 14px; */
        /* color: #4c5258; */
        /* letter-spacing: .5px; */
        background: #fff;
        overflow-x: hidden;
        /* font-family: Roboto, sans-serif; */
    }
    ol, ul {
        list-style: none;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: #32393f;
    }
    .h6, h6{
        font-size: 1rem;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    .setting-drawer-btn{
  border-radius: 6px 0px 0px 6px !important;
}

`